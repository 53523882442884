<template>
  <div>
    <div id="main">
      <div id="help-title">
        <div class="wrap">
          <h2>帮助中心</h2>
        </div>
      </div>
      <div class="help-layout clearfix">
        <div class="help-aside">
          <div class="nav-line">
            <h2 class="title">常见问题分类</h2>
            <div :key="i" v-for="(v, i) in datas" class="class-box">
              <h3
                @click="listActive = v.name"
                :class="{ avtive: listActive == v.name }"
                class="class-title"
              >
                <span>{{ v.name }}</span>
                <i
                  class="icon-drop_right"
                  :style="{ display: listActive !== v.name ? 'block' : 'none' }"
                ></i>
                <i
                  :class="{ avtive: listActive == v.name }"
                  :style="{ display: listActive == v.name ? 'block' : 'none' }"
                  class="icon-drop_down"
                ></i>
              </h3>
              <ul
                :class="{ avtive: listActive == v.name }"
                :style="{ display: listActive == v.name ? 'block' : 'none' }"
                class="menus"
              >
                <li
                  v-for="(p, i) in v.children"
                  @click="activep = `${v.name}/${p.name}`"
                  :key="i"
                  :class="{ active: activep == `${v.name}/${p.name}` }"
                  class=""
                >
                  <a href="#" data-status="1" title="视频播放">{{
                    p.name
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="help-main">
          <div class="help-cate">
            <div class="breadcrumb">
              <span>{{ activep }}</span>
            </div>
            <div v-show="isList" class="cate-content">
              <ul>
                <li v-for="(v, i) in helpList" :key="i">
                  <a href="#" @click="xq(v,v.id)"
                    ><i class="imv2-dot_samll"></i><span>{{ v.name }}</span></a
                  >
                </li>
              </ul>
            </div>

            <div v-show="!isList" class="cate-content">
              <div class="detail-content">
                <div class="detail-title">{{page.name}}</div>
                <p
                  style="
                    margin-top: 0px;
                    margin-bottom: 0px;
                    padding: 0px;
                    box-sizing: border-box;
                    word-break: break-all;
                    word-wrap: break-word;
                    font-size: 16px;
                    line-height: 30px;
                    color: rgb(28, 31, 33);
                    font-family: 'PingFang SC', 微软雅黑, 'Microsoft YaHei',
                      Helvetica, 'Helvetica Neue', Tahoma, Arial, sans-serif;
                    white-space: normal;
                    background-color: rgb(255, 255, 255);
                  "
                >
                 {{page.content}}   </p>
                <p><br /></p>
              </div>
            </div>
          </div>
          <div class="help-link">
            所列问题中未包含您的问题？<a href="//www.imooc.com/user/feedback"
              >点击这里进行反馈</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../ulti/ajax";
export default {
  data() {
    return {
      datas: [],
      listActive: "",
      activep: "",
      helpList: [],
      isList: true,
      page:{}
    };
  },
  async created() {
    let data = (await api.getHelptypeList()).data.rows;
   let arr = {};
   let ider = [];
    for(let pop of data){
     
      if(pop.parentid==0){
        arr[pop.id]={
          name:pop.name,
          parentid:pop.parentid,
          children:[]
        }
      }else{
        if(arr[pop.parentid]){
          arr[pop.parentid].children.push(pop)
        }else{
          arr[pop.parentid] ={}
           arr[pop.parentid].children =[]
          arr[pop.parentid].children.push(pop)
        }
      }
    }
    let p = []
    let match = this.$route.params.pathMatch;
    for(let pop in arr){
      p.push(arr[pop])
      if(arr[pop].name == match.split('/')[0]){
        this.activep = match.split('/')[0]
        this.helpList = arr[pop].children.map(e=>e)
      }
    }
    this.datas = p
  },
  watch: {
    activep: function (newVal, oldVal) {
      let arr = newVal.split("/");
      this.isList = true;
      this.datas.map((e) => {
        if (e.name == arr[1]) {
          e.helptypesubs.map(async (v) => {
            if (v.subname == arr[0]) {
              this.helpList = (await api.getHelpListopction(v.id, 1)).data.data;
            }
          });
        }
      });
    },
  },
  methods: {
   async xq(v,id) {
      let data = await api.getHelpList(14);
      data = data.data.rows;
      this.page.name = v.name
      this.page.content = data[0].remark
      this.isList = false;
    },
  },
};
</script>

<style>
@import url("../../assets/css/helps.css");
.detail-title {
  font-size: 16px;
  color: #1c1f21;
  line-height: 22px;
  margin: 0 0 16px 0;
  font-weight: 700;
}
#help-title {
    width: 100%;
    height: 154px;
    background: url(https://static3.sycdn.imooc.com/static/img/help/help-bg.png) no-repeat;
    background-size: cover;
    background-position: top center
}
</style>